<template>
  <v-card>
    <v-card-title class="text-uppercase font-weight-bold">
      Liste des paiements
    </v-card-title>
    <v-divider></v-divider>
    <br>
    <div class="mx-auto">
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            offset-md="8"
            md="4"
          >
            <v-text-field
              v-model="search"
              :append-icon="icon.mdiMagnify"
              label="Rechercher"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        id="list-refill"
        :headers="headers"
        :items="refillList"
        :search="search"
        :items-per-page="itemPerPage"
        sort-by="state"
        divider="true"
        class="table-kitchen-sink mx-auto"
      >
        <template #[`item.num`]="{item}">
          <div class="d-flex align-center p-5">
            <div class="d-flex flex-column ms-3 ">
              <span class="font-weight-semibold text-body-1 text--primary"> N° {{ item.num }} </span>
              <span class="custom-text--small">Envoyé le : {{ item.open_date }}</span>
              <span
                v-if="item.close_date"
                class="custom-text--small custom-red--text"
              >Fermé le : {{ item.close_date }}</span>
              <v-spacer></v-spacer>
              <v-col
                cols="12"
                class="align-content-end"
              >
                <v-menu
                  offset-y
                  right
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ma-2 custom-btn"
                      text
                    >
                      <v-icon
                        class="error--text"
                      >
                        {{ icon.mdiHistory }}
                      </v-icon>
                      <v-icon
                      >
                        {{ icon.mdiMenuDown }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <!--                  <v-list-item-->
                    <!--                    v-for="(item, index) in items"-->
                    <!--                    :key="index"-->
                    <!--                  >-->
                    <!--                    <v-list-item-title>{{ item.title }}</v-list-item-title>-->
                    <!--                  </v-list-item>-->
                  </v-list>
                </v-menu>
              </v-col>
            </div>
          </div>
        </template>

        <template #[`item.type_depot`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block text-truncate text--primary">N°: <span class="font-weight-semibold"> {{ item.account_debite }}</span></span>
              <span> Montant : <span class="font-weight-semibold custom-red--text">{{ item.amount }} </span></span>
              <v-btn
                class="mt-2 pl-5 pr-5 "
                color="info"
                rounded
                x-small
                outlined
              >
                Consulter la facture
              </v-btn>
            </div>
          </div>
        </template>

        <template #[`item.account_debite`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-semibold text-truncate text--primary">Débiteur: <span class="font-weight-semibold">{{ item.account_debite }}</span></span>
              <span class="d-block font-weight-semibold text-truncate text--secondary">Créditeur: <span class="font-weight-semibold">{{ item.account_credite }}</span></span>
            </div>
          </div>
        </template>

        <template #[`item.amount`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block text-truncate text--primary">Montant: <span class="font-weight-semibold custom--success"> {{ item.amount }}</span></span>
              <span class="d-block text-truncate text--primary">Type: <span class="font-weight-semibold"> {{ item.type_depot }}</span></span>
              <span class="d-block text-truncate text--primary">Service: <span class="font-weight-semibold"> {{ item.moyen_paiement }}</span></span>
              <span class="d-block text-truncate text--primary">Reférence: <span class="font-weight-semibold">{{ item.reference }}</span></span>
              <span  v-if="item.file_name !=='0'" class="d-block text-truncate text--primary">Reçu: <a
                :href="item.file_url"
                target="_blank"
                class="text-decoration-none custom--info"
              ><v-icon class="custom--icon">{{ icon.mdiFileDocumentOutline }}</v-icon> {{ item.file_name }}</a></span>
            </div>
          </div>
        </template>

        <template #[`item.state`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <v-chip
                v-if="item.state==1"
                class="ma-2 text-center pr-5 pl-5"
                color="#F1FFE8FF"
              >
                <b class="success--text">Traité</b>
              </v-chip>
              <v-chip
                v-else
                class="ma-2 text-center"
                color="#FFF6ECFF"
              >
                <b class="custom-warning--text">En cours</b>
              </v-chip>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiFileDocumentOutline,
  mdiMagnify,
  mdiHistory,
  mdiMenuDown,
} from '@mdi/js'
import data from '@/views/finances/refillData'
import Http from '@/helpers/http'

export default {
  setup() {
    const search = ref('')
    const listRefill = ref([])
    const refillList = ref([])
    const itemPerPage = 15

    return {
      icon: {
        mdiFileDocumentOutline,
        mdiMagnify,
        mdiHistory,
        mdiMenuDown,
      },
      search,
      itemPerPage,
      headers: [
        { text: 'Ticket', value: 'num', divider: true},
        { text: 'Facture', value: 'type_depot', divider: true},
        { text: 'Paiement', value: 'amount', divider: true},
        { text: 'État', value: 'state', divider: true},
      ],
      refillList,
      listRefill,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
    }
  },
  watch: {
  },
  mounted() {
    this.refillList = JSON.parse(JSON.stringify(data))
  },
  created() {
    Http.get(
      'local',
    ).then(rep => {
      console.log('Réponse : ', rep)
      this.listRefill = rep
    }).catch(error => {
      console.error('Erreur :', error)
    })
  },
  methods: {
    initialize() {
      this.refillList = JSON.parse(JSON.stringify(data))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/apps/refill.scss';

.custom--icon{
  height: 15px !important;
  width: 15px !important;
  color: #1E9FF2 !important;
}

.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.border{
  border: #0c5460 solid 1px;
}

.custom-warning--text{
  color: darkorange;
  //background-color: #ffcfcf;
}

.custom-btn:hover{
  background-color: transparent !important;
}
</style>
